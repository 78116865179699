/* Name and e-mail */
.contact-form :nth-child(1),
.contact-form :nth-child(2) {
  grid-column: 1;
}

/* Message */
.contact-form :nth-child(3) {
  grid-column: 2;
  grid-row: 1 / 3;
}

/* Button */
.contact-form :nth-child(4) {
  grid-column: span 2;
}

.contact-form {
  margin: 0 auto;
  padding: 2rem;
  width: 60vw;
  background-color: var(--tint-20);
  border-radius: 3px;
  column-gap: 8rem;
  box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 15px;
}

.form-element {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.form-element label p {
  padding-left: 0.5rem;
  padding-bottom: 1rem;
  font-size: 2.2rem;
}

.form-element input {
  padding: 1.2rem;
  font-size: 1.8rem;
  border: none;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
}

/* Message Input*/
.contact-form :nth-child(3) input {
  height: 15vh;
  text-align: start;
}

.btn--form {
  margin: 2rem auto;
  border: none;
  padding: 2rem 5rem;
  background-color: var(--bright-60);
  color: #fff;
  border-radius: 5px;
  font-size: 3.6rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.btn--form:hover,
.btn--form:active {
  background-color: var(--bright-70);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 20px;
}

.text-input {
  font-size: inherit;
  font: inherit;
}

.form-element textarea {
  padding: 1rem;
  border-radius: 5px;
  resize: none;
  max-width: 60rem;
}

/* MEDIA QUERIES */

/* Below 1584px */
@media (max-width: 99em) {
  /* Name and e-mail */
  .contact-form :nth-child(1),
  .contact-form :nth-child(2) {
    grid-row: 1;
    grid-column: span 1;
  }

  /* Message */
  .contact-form :nth-child(3) {
    grid-row: 2;
    grid-column: 1 / 3;
  }

  .form-element textarea {
    max-width: 100%;
  }

  /* Button */
  .contact-form :nth-child(4) {
    grid-column: span 2;
  }
}

/* Below 1088px */

@media (max-width: 68em) {
  .contact-form {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .form-element label p {
    padding-left: 3rem;
  }

  .contact-form input,
  .contact-form textarea {
    width: 85%;
    margin: 0 auto;
  }

  .contact-form :nth-child(n) {
    grid-column: 1;
  }

  /* Name and e-mail */
  .contact-form :nth-child(1) {
    grid-row: 1;
  }
  .contact-form :nth-child(2) {
    grid-row: 2;
  }
  .contact-form :nth-child(3) {
    grid-row: 3;
  }
  .contact-form :nth-child(4) {
    grid-row: 4;
  }
}

/* Below 688px */
@media (max-width: 43em) {
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    margin: 0 auto;
  }

  .contact-form {
    width: 85vw;
  }

  .btn--form {
    padding: 2rem 4rem;
  }

  .form-element label p {
    padding-left: 1rem;
  }
}
