.header-nav {
  list-style: none;
  display: flex;
  margin-right: 17rem;
  gap: 6rem;
}

.header-nav * {
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.header-nav li p {
  margin: 0;
  padding: 0;
}

.header-nav:hover > * {
  opacity: 0.4;
}

.header-nav:hover > *:hover {
  opacity: 1;
}

.header-nav {
  display: flex;
}

@media (max-width: 99em) {
  .header-nav {
    margin-right: 5vw;
  }
}

@media (max-width: 65em) {
  .header-nav {
    display: none;
  }
}
