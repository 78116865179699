.section--home {
  width: 100vw;
  height: 93vh;
  background-image: url("/public/assets/images/home/hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 20% 50%;
  color: #fff;
  padding: 3rem 0;
}

.section--home h1 {
  font-size: 6.4rem;
  color: #fff;
  line-height: 1.4;
  text-transform: uppercase;
}

.hero-container {
  max-width: 160rem;
  margin-right: auto;
}

.hero-description {
  margin: 1rem 0 0 4rem;
  text-align: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 8px;
  max-width: 80rem;
  background-color: rgb(0, 0, 0, 0.65);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.hero-secondary-headline-box h2 {
  text-transform: none;
}

.btn--learn-more,
.btn--cta {
  border: none;
  border-radius: 6px;
  text-transform: uppercase;
  box-shadow: rgba(5, 14, 133, 0.35) 0px 5px 15px;
  font-weight: 500;
}

.btn--learn-more {
  background-color: var(--bright-60);
  color: #fff;
  padding: 1rem 2rem;
  font-size: 2rem;
  margin-right: 2rem;
}

.btn--learn-more:hover,
.btn--learn-more:active {
  background-color: var(--bright-70);
}

.btn--cta {
  color: #fff;
  background-color: var(--admiral-10);
  padding: 2.5rem 2.5rem;
  font-size: 3rem;
}

.btn--cta:hover,
.btn--cta:active {
  background-color: var(--admiral-20);
}

/* Below 1728px */
@media (max-width: 108em) {
  .section--home {
    background-position: 15% 50%;
  }
}

/* Below 1568px */
@media (max-width: 98em) {
  .section--home {
    background-position: 30% 50%;
  }

  .section--home h1 {
    font-size: 5.2rem;
    line-height: 1.3;
  }

  .section--home h2 {
    font-size: 2.8rem;
  }

  .hero-description {
    max-width: 60rem;
  }

  .cta-buttons-box {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }

  .btn--learn-more {
    padding: 1rem 2rem;
    font-size: 1.6rem;
    margin-right: 0;
    font-weight: 500;
    max-width: 20rem;
  }
  .btn--cta {
    padding: 2rem 2rem;
    font-size: 2.4rem;
    font-weight: 500;
  }
}

/* Below 1408px */
@media (max-width: 88em) {
  .section--home {
    background-position: 50% 50%;
  }
}

/* Below 1040px */
@media (max-width: 65em) {
  .section--home {
    background-position: 65% 50%;
  }

  .hero-description {
    max-width: 70rem;
    margin: 0 auto;
  }

  .section--home h1 {
    font-size: 4.4rem;
  }

  .section--home h2 {
    font-size: 2.4rem;
  }
}

/* MOBILE VERSION */

/* Below 688px  */
@media (max-width: 43em) {
  .section--home {
    background-image: url("/public/assets/images/home/hero2.jpg");
    background-position: 20% 50%;
  }

  .hero-description {
    background-color: rgb(0, 0, 0, 0.85);
    max-width: 40rem;
    margin: 0 auto;
  }

  .section--home h1 {
    font-size: 3.6rem;
  }

  .section--home h2 {
    font-size: 1.8rem;
  }

  .hero-secondary-headline-box h2 {
    padding: 2rem;
  }
}

/* Below 416px  */
@media (max-width: 26em) {
  .section--home {
    background-position: 30% 50%;
  }

  .hero-description {
    max-width: 32rem;
    padding: 1rem;
  }

  .section--home h1 {
    font-size: 3rem;
  }

  .section--home h2 {
    font-size: 1.6rem;
  }

  .hero-secondary-headline-box h2 {
    padding: 1.5rem;
  }

  .btn--learn-more {
    font-size: 1.4rem;
  }

  .btn--cta {
    padding: 1.5rem 1.5rem;
    font-size: 2rem;
  }
}
