.Member-box {
  max-width: 70rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem;
}

.Member-description-box {
  text-align: center;
}

.Member-name {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 3rem;
  margin: 4rem 0 8rem 0;
}

.Member-description-line {
  display: flex;
  gap: 2rem;
  padding: 1rem;
  text-align: center;
  font-size: 2.2rem;
}

.Member-link,
.Member-position {
  text-align: start;
}

.Member-description-line address * {
  color: #fff;
  font-style: normal;
}

@media (max-width: 31em) {
  .Member-box {
    grid-template-columns: 1fr;
  }

  .Member-name {
    margin-bottom: 3rem;
  }

  .Member-description-box,
  .Member-image-box {
    padding: 4rem;
  }

  .Member-image-box {
    padding-top: 0;
    grid-row: 3 / span 1;
  }
}
