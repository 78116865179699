.section--contact {
  background-color: var(--tint-10);
  color: #fff;
}

section .contact-headline,
section .contact-next-events {
  width: 70vw;
  text-transform: uppercase;
  text-align: center;
  padding: 4rem;
}

.contact-next-events-logo-box {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
  max-width: 50rem;
}

.logo-image-box,
.logo-image-box img {
  border-radius: 5px;
}

.logo-image-box {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 0.3s linear;
}

.logo-image-box:hover {
  transform: translateY(-10px);
}

.logo-image-box img {
  max-width: 100%;
  height: auto;
}

.contact-headline p {
  padding: 2rem 0;
}

.contact-next-events p {
  padding: 4rem 0;
}

.contact-headline p:nth-child(1) {
  font-size: 3.6rem;
}

.contact-headline p:nth-child(2) {
  font-size: 2.4rem;
}

.contact-footer {
  width: 70vw;
  padding: 4rem;
  padding-top: 0;
}

.contact-footer p {
  text-align: center;
  padding: 4rem;
}

/* QUERIES */

/* Below 688px */
@media (max-width: 43em) {
  .contact-headline p:nth-child(1) {
    font-size: 3rem;
  }

  .contact-headline p:nth-child(2) {
    font-size: 2rem;
  }

  .contact-footer p {
    padding: 4rem 0;
  }

  .contact-next-events-logo-box {
    flex-direction: column;
  }
}

/* Below 496px */
@media (max-width: 31em) {
  section .contact-next-events {
    width: 100%;
  }
}

/* Below 432px */
@media (max-width: 27em) {
  section .contact-headline,
  section .contact-footer {
    padding: 4rem 0;
  }
}
