.home-btn-box {
  height: 5.5rem;
  background: none;
  border: none;
}

.logo {
  width: inherit;
  height: inherit;
}
